import React, { useState, useEffect } from 'react';
import './App.css';

const allImages = [
  '/pfp1.jpg',
  '/pfp2.jpg',
  '/pfp3.jpg',
  '/pfp4.jpg',
  '/pfp5.jpg',
  '/pfp6.jpg',
  '/pfp7.jpg',
  '/pfp8.jpg',
  '/pfp9.jpg',
  '/pfp10.jpg',
  '/pfp11.jpg',
  '/pfp12.jpg',
  '/pfp13.jpg',
  '/pfp14.jpg',
  '/pfp15.jpg',
  '/pfp16.jpg',
  '/pfp17.jpg',
  '/pfp1.jpg',
  '/pfp2.jpg',
  '/pfp3.jpg',
  '/pfp4.jpg',
  '/pfp5.jpg',
  '/pfp6.jpg',
  '/pfp7.jpg',
  '/pfp8.jpg',
  '/pfp9.jpg',
  '/pfp10.jpg',
  '/pfp11.jpg',
  '/pfp12.jpg',
  '/pfp13.jpg',
  '/pfp14.jpg',
  '/pfp15.jpg',
  '/pfp16.jpg',
  '/pfp17.jpg'
];

function App() {
  const [bitcoinAddress, setBitcoinAddress] = useState('');
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [whitelistedAddresses, setWhitelistedAddresses] = useState([]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await fetch('/withoutDuplicates.txt');
        const data = await response.text();
        const addresses = data.split('\n').map(line => line.trim()).filter(line => line !== '');
        setWhitelistedAddresses(addresses);
      } catch (err) {
        console.error('Failed to fetch addresses:', err);
      }
    };

    fetchAddresses();

    const textElement = document.querySelector('.dripping-text');
    const spans = textElement.querySelectorAll('span');

    spans.forEach((span, index) => {
      const randomDelay = Math.random() * 10 + 2;
      const randomDuration = Math.random() * 2 + 6;
      span.style.setProperty('--random-delay', `${randomDelay}s`);
      span.style.setProperty('--random-duration', `${randomDuration}s`);
    });

    const numLogos = 7;
    const existingLogos = document.querySelectorAll('.background-logo');
    if (existingLogos.length === 0) {
      for (let i = 0; i < numLogos; i++) {
        const logo = document.createElement('img');
        logo.src = '/bitcoin-logo.svg';
        logo.className = 'background-logo';
        logo.style.top = `${Math.random() * 100}vh`;
        logo.style.left = `${Math.random() * 100}vw`;
        logo.dataset.vx = (Math.random() * 2 - 1) * 0.24;
        logo.dataset.vy = (Math.random() * 2 - 1) * 0.24;
        document.body.appendChild(logo);
      }
    }

    function moveLogos() {
      const logos = document.querySelectorAll('.background-logo');
      logos.forEach(logo => {
        let x = parseFloat(logo.style.left);
        let y = parseFloat(logo.style.top);
        let vx = parseFloat(logo.dataset.vx);
        let vy = parseFloat(logo.dataset.vy);

        x += vx;
        y += vy;

        if (x <= 0 || x >= window.innerWidth - 50) {
          vx *= -1;
          x = Math.max(0, Math.min(x, window.innerWidth - 50));
        }
        if (y <= 0 || y >= window.innerHeight - 50) {
          vy *= -1;
          y = Math.max(0, Math.min(y, window.innerHeight - 50));
        }

        logo.style.left = `${x}px`;
        logo.style.top = `${y}px`;
        logo.dataset.vx = vx;
        logo.dataset.vy = vy;
      });

      requestAnimationFrame(moveLogos);
    }

    moveLogos();

    const numJoysticks = 7;
    const existingJoysticks = document.querySelectorAll('.background-logo-joystick');
    if (existingJoysticks.length === 0) {
      for (let i = 0; i < numJoysticks; i++) {
        const joystick = document.createElement('img');
        joystick.src = '/joystick.svg';
        joystick.className = 'background-logo-joystick';
        joystick.style.top = `${Math.random() * 100}vh`;
        joystick.style.left = `${Math.random() * 100}vw`;
        joystick.dataset.vx = (Math.random() * 2 - 1) * 0.24;
        joystick.dataset.vy = (Math.random() * 2 - 1) * 0.24;
        document.querySelector('.description').appendChild(joystick);
      }
    }

    function moveJoysticks() {
      const joysticks = document.querySelectorAll('.background-logo-joystick');
      joysticks.forEach(joystick => {
        let x = parseFloat(joystick.style.left);
        let y = parseFloat(joystick.style.top);
        let vx = parseFloat(joystick.dataset.vx);
        let vy = parseFloat(joystick.dataset.vy);

        x += vx;
        y += vy;

        if (x <= 0 || x >= window.innerWidth - 50) {
          vx *= -1;
          x = Math.max(0, Math.min(x, window.innerWidth - 50));
        }
        if (y <= 0 || y >= window.innerHeight - 50) {
          vy *= -1;
          y = Math.max(0, Math.min(y, window.innerHeight - 50));
        }

        joystick.style.left = `${x}px`;
        joystick.style.top = `${y}px`;
        joystick.dataset.vx = vx;
        joystick.dataset.vy = vy;
      });

      requestAnimationFrame(moveJoysticks);
    }

    moveJoysticks();

    const scrollToTopBtn = document.querySelector('.scroll-to-top');
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        scrollToTopBtn.classList.add('scrolled');
      } else {
        scrollToTopBtn.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setBitcoinAddress(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  const handleSearch = () => {
    if (!bitcoinAddress.startsWith('bc1p')) {
      setMessage('The wallet you entered is in the wrong structure, please enter a taproot address.');
      setMessageColor('orange');
    } else if (whitelistedAddresses.includes(bitcoinAddress)) {
      setMessage('Congratulations, your wallet is whitelisted. See you on the 26th of June!');
      setMessageColor('green');
    } else {
      setMessage('Sorry, your wallet is not whitelisted.');
      setMessageColor('red');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="dripping-text">
          <span>B</span>
          <span>I</span>
          <span>T</span>
          <span>C</span>
          <span>O</span>
          <span>I</span>
          <span>N</span>
          <br />
          <span>M</span>
          <span>O</span>
          <span>N</span>
          <span>S</span>
          <span>T</span>
          <span>E</span>
          <span>R</span>
          <span>S</span>
        </h1>
        <div className="free-mint">
          <span className="line"></span>
          <span>FREE MINT</span>
          <span className="line"></span>
        </div>
        <div className="icons">
          <a href="https://discord.com/invite/wyGy6cBm5B" target="_blank" rel="noopener noreferrer">
            <img src="/discord-svg.svg" alt="Discord" className="icon" />
          </a>
          <a href="https://x.com/btcmonsters" target="_blank" rel="noopener noreferrer">
            <img src="/twitter-svg.svg" alt="Twitter" className="icon" />
          </a>
        </div>
      </header>
      <section className="description">
        <div className="description-text">
          <h2>Introducing Monster<br />Universe P2E!</h2>
          <p>Join our unique 5k collection through a Free Mint event. Each monster is a one-of-a-kind digital collectible, offering ownership, gameplay, and earning potential.</p>
          <p>&gt; Mint price: Free<br />
             &gt; Mint date: June 26th<br />
             &gt; Collection size: 5,000</p>
        </div>
        <div className="description-image">
          <img src="/pfp18.jpg" alt="Monster" style={{ width: '150%', borderRadius: '10px' }} />
        </div>
      </section>
     
      <section className="carousel-section">
        <div className="carousel-wrapper">
          <div className="carousel-container top-carousel">
            {allImages.map((image, index) => (
              <div key={index} className="carousel-item">
                <img src={image} alt={`carousel-item-${index}`} className="carousel-image" />
              </div>
            ))}
          </div>
          <div className="carousel-container bottom-carousel">
            {allImages.map((image, index) => (
              <div key={index} className="carousel-item">
                <img src={image} alt={`carousel-item-${index}`} className="carousel-image" />
              </div>
            ))}
          </div>
        </div>
      </section>
      <img src="/uptick.svg" alt="Scroll to top" className="scroll-to-top" onClick={scrollToTop} />
    </div>
  );
}

export default App;
